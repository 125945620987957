/*.................Alignment......................*/
.centerAlign {
  text-align: center;
}

.rightAlign {
  text-align: right;
}

.ctgryImg {
  width: 80px;
  height: 80px;
  margin: 5px;
}
/*....................Search Bar....................*/

/* .commonInput i {
  position: absolute;
  bottom: 2.5rem;
  z-index: 2;
  left: -9.4rem;
} */
/*....................Filter responsive....................*/

@media (max-width: 575px) {
  .commonInput {
    float: left !important;
    margin-top: 8px;
  }
}

@media (max-width: 575px) {
  .commonIn {
    float: left !important;
    margin-top: 8px;
  }
}

@media (max-width: 767px) {
  .commonInFilter {
    margin-top: 8px;
  }
}

/*....................Filter Bar.................*/

.commonFilterDiv {
  width: 100% !important;
  margin-top: 0px !important;
}

.commonFilterSelect {
  width: 80% !important;
  height: 35px;
  border: 1px solid #8080808c;
  background: white;
  border-radius: 4px;
}
.commonFilterSelect:focus-visible {
  border: 1px solid green;
  outline-color: green;
}

.cmnFilterForm.form-control {
  width: 245px;
  color: #5c6873;
  background-color: #fff;
  border: 1px solid #0000004d;
  border-radius: 0rem;
  display: block;
  height: calc(1.7em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/*.....................Count Result................*/
.commonRecrd {
  float: right !important;
}

.cmnRecordIcon {
  font-size: 15px;
}

.cmnResutText {
  opacity: 0.7;
}

.cmnPageCount {
  padding: 5px;
  font-weight: 600;
  font-size: 18px;
  opacity: 0.7;
}

@media (max-width: 575px) {
  .commonRecrd {
    float: left !important;
  }
}

/* .................Table .........................*/

.cmnMainTable th {
  /* text-align: center; */
  background-color: #0077b6;
}

.cmnMainTableHd {
  text-transform: capitalize;
  font-size: 15px;
  color: #f5f5f5;
}

.cmn_Cap {
  text-transform: capitalize;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cmnViewIcon {
  cursor: pointer;
  color: #233e56 !important;
}
.cmnViewIconBan {
  cursor: not-allowed;
  color: #233e56 !important;
}
/*...................Order Pages Search Styling...........*/
.userSrchList {
  max-height: 150px;
  width: 100%;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.45);
  overflow-y: scroll;
  z-index: 1;
  position: absolute;
  background-color: #dddddd;
}

.btnStylefleet {
  width: 100%;
  padding: 2%;
  text-align: left;
}

/* ................edit model page................... */

.cmnEditForm .form-control:focus {
  color: #5c6873;
  background-color: #fff;
  border: 1px solid #e4e7ea;
  border-color: #06851d !important;
  outline: 0;
  box-shadow: 0 0 0 0 rgba(32, 168, 216, 0.25);
}

/* ..................view model page................*/
.cmnViewDiv {
  height: 280px !important;
  overflow-x: hidden !important;
  width: 70% !important;
  margin: auto;
}

.cmnActivCanclBtn {
  padding-left: 18%;
  padding-right: 18%;
  float: right;
  background-color: #0077b6 !important;
  border-color: #0077b6 !important;
  color: white !important;
}

.cmnActivCanclBtn:hover {
  background-color: #0077b6 !important;
  border-color: #0077b6 !important;
  color: white !important;
}

.cmnActivCanclBtn:active {
  background-color: #0077b6 !important;
  border-color: #0077b6 !important;
  color: white !important;
}

.cmnActivCanclBtn:focus {
  border-color: #0077b6 !important;
  background-color: #0077b6 !important;
  outline: 0;
  box-shadow: 0 0 0 0rem #0077b6 !important;
}

.cmnActivOkBtn {
  padding-left: 12%;
  padding-right: 12%;
  background-color: #0077b6 !important;
  border-color: #0077b6 !important;
  color: white !important;
}

.cmnActivOkBtn:hover {
  background-color: #0077b6 !important;
  border-color: #0077b6 !important;
  color: white !important;
}

.cmnActivOkBtn:active {
  background-color: #ccc !important;
  border-color: #ccc !important;
  color: white !important;
}

.cmnActivOkBtn:focus {
  border-color: #ccc !important;
  background-color: #ccc !important;
  outline: 0;
  box-shadow: 0 0 0 0rem #ccc !important;
}

.Commondetlsmodelview {
  overflow-x: hidden !important;
  width: 70% !important;
  margin: auto;
}

@media (max-width: 991px) and (min-width: 320px) {
  .Commondetlsmodelview {
    width: 100% !important;
  }
}
/* ....................selectbox........................... */
.commonselectbox .form-control {
  width: 245px;
  margin-left: -40px;
  color: #5c6873;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #0000004d;
  border-radius: 0rem;
  display: block;
  height: calc(1.7em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.commonselectbox .form-control:focus {
  color: #5c6873;
  background-color: #fff;
  border: 1px solid #0000004d;
  outline: 0;
  box-shadow: 0 0 0 0 rgba(32, 168, 216, 0.25);
}

.commonselectbox .form-group {
  margin-bottom: 0px;
}

/* ..................Delete modal box.....................*/
@media (min-width: 576px) {
  .modal-sm {
    max-width: 500px !important;
  }
}
.cmnDelCnclBtn {
  width: 100px;
  float: right;
  background-color: rgba(73, 71, 71, 0.76) !important;
  border-color: #ccc !important;
  border-radius: 50px !important;
  color: #fff;
  border: 1px solid rgba(73, 71, 71, 0.76);
}

.cmnDelCnclBtn:hover {
  background-color: rgb(143, 140, 140) !important;
  border-color: #ccc !important;
  border-radius: 50px !important;
  color: #fff;
}

.cmnDelCnclBtn:active {
  background-color: #ccc !important;
  border-color: #ccc !important;
  border-radius: 50px !important;
  color: #fff;
}

.cmnDelCnclBtn:focus {
  background-color: #ccc !important;
  border-color: #fff;
  border-radius: 50px !important;
  outline: 0;
  box-shadow: 0 0 0 0rem #ccc !important;
}

.cmnDelOkBtn {
  width: 100px;
  color: white;
  border-radius: 50px !important;
  background-color: rgba(201, 10, 10, 0.87) !important;
  border-color: rgba(201, 10, 10, 0.87) !important;
}
.cmnShareBtn,
.cmnShareBtn:hover {
  width: 100px;
  color: white;
  border-radius: 50px !important;
  background-color: #06851d !important;
  border-color: #06851d !important;
}
.cmnShareBtn:focus {
  outline: 0;
  box-shadow: 0 0 0 0rem #723895cc !important;
}
.cmnDelOkBtn:hover {
  background-color: rgba(201, 10, 10, 0.87) !important;
  border-color: rgba(201, 10, 10, 0.87) !important;
  color: white !important;
  border-radius: 50px;
}

.cmnDelOkBtn:active {
  background-color: rgba(201, 10, 10, 0.87) !important;
  border-color: rgba(201, 10, 10, 0.87) !important;
  color: white !important;
}

.cmnDelOkBtn:focus {
  border-color: rgba(201, 10, 10, 0.87) !important;
  background-color: rgba(201, 10, 10, 0.87) !important;
  outline: 0;
  box-shadow: 0 0 0 0rem #06851d !important;
}

/* .................Description dot style................... */

.commonDesc {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.commonDesc1 {
  max-width: 125px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/*.................No data image.......................*/

.noDataTextDiv {
  text-align: center;
  opacity: 0.6;
}

.noDataImg {
  /* width: 50%; */
  /* width: 35%; */
  width: 20%;
  margin: auto;
  justify-content: center;
  display: flex;
  /* opacity: 0.6; */
}

/*.....................Pagination..............................*/
.paginationDiv {
  justify-content: center;
  display: flex;
  margin-top: -15px;
}

.paginationDiv .pagination {
  margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none;
}

.paginationDiv .pagination > .active > a {
  background-color: #0077b6;
  border-color: #0077b6;
  color: #fff;
}

.paginationDiv .pagination > li > a {
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}

.paginationDiv .pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #0077b6;

  outline: none;
}

.paginationDiv .pagination > li > a,
.pagination > li > span {
  color: #0077b6;
}

.paginationDiv .pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: unset;
}

.paginationDiv .pagination > .disabled > a {
  color: #0077b6 !important;
  cursor: default;
}

/* ................Detailed View..................*/
.storeModalCap {
  text-transform: capitalize;
  width: 300px;
}
.storeModalTbl {
  overflow-x: hidden !important;
  width: 70% !important;
  margin: auto;
}
.storeSecndModalTbl {
  width: 70% !important;
  margin: auto;
}

.storeTdWdth {
  width: 200px;
}

.storeNavdiv .nav {
  margin-left: 125px;
}

.storeModalDesc {
  text-align: justify;
}

/* ....................Image style....................... */

.storeImg {
  width: 80px;
  height: 80px;
  margin: 5px;
}

/* .........................common style..................*/
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #00000008 !important;
}

.detailModal {
  padding-top: 9.5rem;
}
.detailModal {
  padding-top: 8.5rem;
  /* background-color: rgba(0, 0, 0, 0.2); */
}

.detailModal2 {
  padding-top: 1.5rem;
  /* background-color: rgba(0, 0, 0, 0.2); */
}

.detailModal .modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
  /* height: 700px !important; */
}

@media (max-width: 991px) and (min-width: 320px) {
  .storeSecndModalTbl {
    width: 100% !important;
    height: unset !important;
  }
  .storeNavdiv .nav {
    margin-left: 15px;
  }
}

/*....................Common usage......................*/
.boldClass {
  font-weight: bold;
}

.storeprisptnImg {
  margin: auto;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 41px;
  width: 45px;
  height: 42px;
}

.imageModalClass {
  width: 50px;
  height: 50px;
}
.chemistdetailspera {
  margin-bottom: 6px !important;
}
.underprocessImg {
  width: 25%;
  justify-content: center;
  align-items: center;
  margin: auto;
  display: flex;
  padding-bottom: 10px;
  padding-top: 12%;
}

.modelboxdesigns1 .card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #0077b6;
  border-radius: 0.25rem;
  width: 55em;
  margin-left: -20px;
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
  font-size: 30px;
  color: #f5f5f5;
  outline: none;
}

.profilebasicdetailslicenseename {
  font-weight: 500 !important;
  font-size: 16px !important;
  font-family: Roboto, sans-serif !important;

  margin: 0;
}

.profilebasicdetailscontper {
  font-weight: 500 !important;
  font-size: 14px !important;
  height: 40px !important;
  line-height: 16px;
  text-transform: capitalize;
  margin: 0;
  font-family: Roboto, sans-serif !important;
}
.upiDetail {
  font-weight: 500 !important;
  font-size: 14px !important;
  height: 40px !important;
  line-height: 16px;
  /* text-transform:lowercase; */
  margin: 0;
  font-family: Roboto, sans-serif !important;
}

/* ::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
  display: none;
} */

.scrollbar-container.sidebar-nav.ps.ps-container.ps--active-y {
  background-color: #f8f9fa;
}

::-webkit-scrollbar-track {
  /* ::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
} */

  /* ::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.5);
}
  -webkit-box-shadow: inset 0 0 4px rgba(202, 29, 29, 0.5);
} */
}
.sidebar-minimized .sidebar .nav-dropdown-items .nav-item .nav-link {
  width: 265px;
  background-color: #ffffff;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(221, 67, 67);
  border-radius: 10px;
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 4px rgba(202, 29, 29, 0.5) !important;
}

/* ------------ */
/* ::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0);
	background-color: #F5F5F5;
}
::-webkit-scrollbar
{
	width: 3px;
	background-color: #F5F5F5;
}
::-webkit-scrollbar-thumb
{
  background-color: rgba(0, 0, 0, 0.8);
} */
/* ------------ */

.agent-avatar {
  margin: auto;
  width: 37px;
  position: relative;
}

.basic-prof-hd {
  font-family: Roboto, sans-serif !important;
  font-size: 16px !important;
  font-style: bold;
  margin: 0px;
  color: #3e5669;
  font-weight: 600;
}
.basic-prof-dtls {
  font-family: Roboto, sans-serif !important;
  font-style: normal;
  font-size: 14.5px !important;
  text-transform: capitalize;
  line-height: 19px;
  height: 37px !important;
  color: black;
}

.profdtls-email {
  font-size: 14px !important;
  height: 37px !important;
  line-height: 19px;
  color: black;
  font-family: Roboto, sans-serif !important;
  font-style: normal;
}
.dtls-head-align {
  color: #0077b6;
  font-weight: 500;
  font-size: 19px;
}
.detail-modal-align {
  /* padding-top: 8.5rem !important; */
  background-color: rgba(14, 13, 13, 0.2) !important;
}

.model-Head-detailview {
  padding-top: 31px;
  font-size: 35px;
  background-color: #f0f3f5 !important;
}
/* .detail-modal-align ::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
  display: block !important;
}
.detail-modal-align ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.detail-modal-align ::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: grey !important;
} */
.prof-image {
  border-radius: 80px;
  width: 65px;
  height: 65px;
  margin-bottom: -67px;
  margin-left: 20px;
}
/* .img-avatar {
  width: 65px !important;
  height: 65px !important;
} */
.img-avatar-headr {
  width: 50px !important;
  height: 50px !important;
  margin-right: 20px !important;
}
.viewmodal-title span {
  text-transform: capitalize;
}
.viewmodal-title {
  margin-left: 20px;
}
.feedbkModalTbl-agnt-dtl {
  height: 276px !important;
  overflow-x: hidden !important;
  margin: auto;
}
.feedbkModalTbl-agnt-dtl table {
  margin-bottom: 0px !important;
}
.feedbkModalTbl-agnt-dtl .lft-align {
  margin-top: 15px;
}
.otr-row-align {
  margin-top: 24px;
}
.dtls-head-align {
  color: #0077b6 !important;
  font-weight: 500 !important;
  font-size: 17px !important;
}
/*....................Common usage master data modal......................*/
.mastr-mdl-hd {
  font-size: 35px;
  background-color: #0077b6;
  color: #f5f5f5;
}
.modal-header.close {
  color: #f5f5f5;
}
.mastr-mdl-ftr {
  background-color: #fff;
}

.detail-ad-cat-Modal {
  padding-top: 5.4rem !important;
  background-color: rgba(0, 0, 0, 0.2) !important;
}
.detail-ad-Modal {
  padding-top: 8.5rem;
  background-color: rgba(0, 0, 0, 0.2);
}
.tble-add-btn {
  margin-bottom: 15px !important;
}
.add-btn-align-icn {
  margin-left: 5px;
  font-weight: bold;
}
.tabl-slNo-field {
  width: 70px;
}
.modl-titl-align {
  font-size: 20px;
}
.is-active-lbl {
  display: flex;
  margin-top: 8px;
}
.delete-msg {
  text-align: center;
  margin-top: 20px;
  color: #5f5353;
  font-size: 17px;
}
.delete-btn-rw {
  margin-bottom: 20px;
  margin-top: 30px;
}
.search-tbl-th {
  width: 200px;
}
.form-grp-wd {
  width: 100%;
}

/* ----------------common view modal------------ */

.viewModalCap {
  text-transform: capitalize;
  width: 300px;
}
.viewModalTbl {
  overflow-x: hidden !important;
  /* width: 70% !important; */
  margin: auto;
  max-width: 550px;
}
.viewModalTdWdth {
  width: 150px;
}

/* -------------------Common Error message------------------ */
.errStyle {
  color: red;
  font-size: 12px;
}
.errStyle1 {
  font-size: 13px;
  color: red;
  position: relative;

  bottom: 16px;
}
.errDataStyle {
  color: red;
  font-size: 14px;
}
.mandatoryField:after {
  content: " *";
  color: red;
}
.btn_cmn:active {
  background-color: #0077b6;
}
.btn_cmn {
  background-color: #0077b6;
}
.btn_cmn:hover {
  background-color: #0077b6;
}
.btn_cmn:focus {
  background-color: #0077b6;
}

.cmnRecrd {
  float: right;
  margin-top: 8px;
}
@media (max-width: 575px) {
  .cmnRecrd {
    float: left !important;
  }
}

/* .............................Responsive view....................... */

@media only screen and (max-width: 600px) {
  /* .nav-tabs .nav-link {
    width: 35%;
    padding-left: 2%;
    padding-right: 0%;
  }
  .nav-tabs .nav-link.active {
    width: 35%;
    padding-left: 2%;
    padding-right: 0%;
  }
  .nav-tabs .nav-link.active:focus {
    width: 35%;
    padding-left: 2%;
    padding-right: 0%;
  } */
}

/*....................................................................*/
.salesreturndiv {
  border: 2px solid;
  margin-top: 6rem !important;
  width: 50%;
  display: flex;
  border: 1px solid #00000024;
  margin: auto;
  padding-top: 1.5rem;
  align-items: center;
  justify-content: center;
}

.salesreturndiv .card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.2rem;
  box-shadow: 0 2px 4px rgba(126, 142, 177, 0.12) !important;
  margin-bottom: 1.5rem;
}
.salesreturndiv .table tbody + tbody {
  border-top: 1px solid #c8ced3;
}

/* .nav-tabs .nav-link.active,
.nav-tabs .nav-link.active:focus {
  background: #67B7D1;
  border-color: #67B7D1;
  padding-left: 6%;
  padding-right: 6%;
} */
.tab-content .tab-pane {
  /* padding: 1rem; */
  padding: 0px !important;
}

.nav-tabs {
  border-bottom: 0px solid #0077b6;
  margin-left: 10px;
  margin-top: 54px;
}
.eftabs {
  border-bottom: initial;
  margin-left: initial;
  margin-top: initial;
}
.tab-content {
  margin-top: 0px;
  /* background: #fff; */
  border: 0px solid #c8ced3;
  margin-bottom: 25px;
}

/* -------------------------Common View Page--------- */
.cmnViewHead {
  font-weight: 500;
  font-size: 14px;
  color: #3e5669;
  margin: 0;
}

.cmnViewData {
  font-weight: 500;
  font-size: 14px;
  /* height: 60px;
  line-height: 16px; */
  margin: 0;
  text-transform: capitalize;
  text-align: justify;
}

.cmnViewDiv {
  margin-bottom: 20px;
  margin-top: 20px;
}
.cmnViewRow {
  margin-bottom: 10px;
}

/* .........................Text Capital............... */

.cmnTextCaptital {
  text-transform: capitalize;
}
.cmnTextDesc {
  text-transform: capitalize;
  text-align: justify;
}
/*........................Common filter icon...............*/
.cmnfilter {
  color: #06851d;
  font-size: 20px;
  cursor: pointer;

  padding: 9px 25px 9px 12px;
  border-radius: 30px;

  border: 1px solid #06851d;
  height: 38px;
}
.btnrow {
  margin-bottom: 28px;
}
/* .cmnfilter {
  float: right;

  color: #06851d;
  font-size: 20px;
  cursor: pointer;

  padding: 9px 25px 9px 12px;
  border-radius: 30px;

  border: 1px solid #06851d;
  height: 38px;
} */

.cmnfilter::after {
  content: "Filter..";
  font-size: 16px;
  padding-left: 12px;
  color: black;
  font-family: var(--font-family-sans-serif);
  font-weight: bolder;
}

.counter-box {
  border-radius: 5px;

  display: block;
  background: #0077b6;
  /* padding: 40px 20px 37px; */
  text-align: center;
  /* height:80px; */
  width: 150px;
  border: 2px solid #0077b6;
}
.totlAmntStyle {
  /* padding: 5px; */
  color: #fff;
  font-size: 18px;
  font-weight: bold;
}

.counter-box p {
  margin: 5px 0 0;
  padding: 0;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
}

.counter-box i {
  font-size: 60px;
  margin: 0 0 15px;
  color: #d2d2d2;
}

.counter {
  display: block;
  font-size: 22px;
  font-weight: 700;
  color: #666;
  line-height: 10px;
}

.counter-box.colored {
  background: #0077b6;
}

.counter-box.colored p,
.counter-box.colored i,
.counter-box.colored .counter {
  color: #fff;
}

/* scrolll */

/* .scrolltable
{
  margin-top: 15px;
  overflow: auto;
  height: 230px;
  scroll-behavior: smooth;
  scrollbar-width: auto !important;
  margin-bottom: 30px;
  -webkit-appearance: none;
  padding-left: 60px;
  padding-right: 60px;
} */
.astrk {
  color: red;
}
.label-inpt {
  font-size: 13.5px;
  text-transform: capitalize;
  margin-top: 10px;
  color: black;
}

.resetimg {
  width: 50px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

/* facebook connected div */
.cmnFbDiv {
  width: 180px;
  /* padding: 50px; */
  border: 1px solid #e4e7ea;
  border-radius: 0.25rem;
  /* height: calc(1.5em + 0.75rem + 2px); */
  /* padding: 0.375rem 0.75rem; */
  /* font-size: 0.875rem; */
  font-weight: 400;
  /* line-height: 1.5; */
  /* color: black; */
  background-color: white;
  text-align: center;
  padding: 10px;
  padding: 5px;
}
.facebookCnct {
  font-size: 25px;
  color: #0077b6;
  margin-right: 10px;
  /* padding-left: 10px;
  padding-right: 10px; */
}
.connectLbl {
  font-size: 16px;
  font-weight: bold;
}
.custCreditLbl {
  font-size: 16px;
  font-weight: bold;
  color: #0077b6;
  cursor: pointer;
}
.fbConctTick {
  font-size: 25px;
  color: green;
  margin-left: 10px;
}

/* Detail view heading style */
.cmnDetailHead {
  float: left;
  font-size: 18px;
  font-weight: 700;
  color: #0077b6;
  margin-top: 20px;
}

/* QRcode style */
.viewqrimage {
  height: 125px;
  width: 125px;
  /* padding: 10px; */
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  display: flex;
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
  margin-top: 15px;
  margin: auto;
}
.starIconstyle {
  color: goldenrod;
}
.pageCloseIcon {
  margin-top: 0px;
  margin-right: 25px;
  color: #0077b6;
}
.pageRightCloseIcon {
  margin-left: 119px;
  color: #0077b6;
  margin-top: 0px;
}
.starIconstyle-ledger {
  color: goldenrod;
  font-size: 20px;
}

.ledger-amount-main {
  display: flex;
  gap: 10px 20px;
}

.totlAmntStyle-ledger {
  color: #fff;
  font-size: 14px;
  font-weight: bold;
}

.counter-box-ledger {
  border-radius: 5px;
  margin-bottom: 14px;
  display: block;
  background: #0077b6;

  text-align: center;

  width: 186px;
  border: 2px solid #0077b6;
}

.counter-box-ledger.colored {
  background: #0077b6;
}

.counter-box-ledger p {
  margin: 5px 0 0;
  padding: 0;
  color: #fff;
  font-size: 11px;
  font-weight: bold;
}

.counter-box-ledger i {
  font-size: 60px;
  margin: 0 0 15px;
  color: #d2d2d2;
}

/* image close icon product */
.imgCloseIcon {
  position: absolute;
  right: 100px;
  top: 73px;
  width: 40px;
  height: 32px;
  opacity: 1;
}
.imgCloseIcon:hover {
  opacity: 1;
}

/* check icon style for lead order */
.CheckIconStyle {
  color: green;
  font-size: 25px;
}
.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 12px;
  border-bottom: 1px solid #c8ced3;
  border-top-left-radius: 0.7rem;
  border-top-right-radius: 0.7rem;
  background-color: #4e4e4e;
  color: white;
}
/* ======================table=========================== */
.table thead th {
  /* vertical-align: bottom;
  border-bottom: 2px solid  #06851d;
  text-align: left; */
  text-align: left;
  vertical-align: bottom;
  background-color: #06851d;
  color: white;
}
.cmnMainTable th {
  text-align: center;
  background-color: #06851d;
  border: #06851d;
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  /* -webkit-overflow-scrolling: touch; */
  border-radius: 8px;
}
.mainCardDiv {
  margin-top: 55px;
  padding: 0px 110px;
}
.mainCardDiv .card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #c8ced3;
  border-radius: 10px;
  margin-top: 25px;
}
/* ======================================= */
.sidebar-fixed .app-header + .app-body .sidebar {
  height: calc(96vh - 55px);
  /* box-shadow: 0 2px 2px 0
      rgba(var(--cui-elevation-base-color, 60, 75, 100), 0.14),
    0 3px 1px -2px rgba(var(--cui-elevation-base-color, 60, 75, 100), 0.12),
    0 1px 5px 0 rgba(var(--cui-elevation-base-color, 60, 75, 100), 0.2); */
}
.commonaddbutton.btn-primary {
  color: #242121;
  background-color: white;
  border-color: #06851d;
  width: 112px;
  padding-bottom: 8px;
  padding-top: 9px;
  box-shadow: 0 0 0 0 rgba(65, 181, 222, 0.5);
  border-radius: 30px;
}

.commonaddbutton.btn-primary:hover {
  color: #fcf9f9 !important;
  background-color: #06851d;
  border-color: #06851d;
  width: 112px;
  padding-bottom: 8px;
  padding-top: 9px;
  box-shadow: 0 0 0 0 rgba(65, 181, 222, 0) !important;
  border-radius: 30px;
}
.commonaddbutton.btn-primary:active {
  color: #fcf9f9 !important;
  background-color: #06851d;
  border-color: #06851d;
  width: 8%;
  padding-bottom: 8px;
  padding-top: 9px;
  box-shadow: 0 0 0 0 rgba(65, 181, 222, 0) !important;
  border-radius: 30px;
}

.commonaddicon {
  margin-right: 7px;
  color: #707070;
}
.mainCardDiv .table {
  width: 100%;
  margin-bottom: 0rem;
  color: #23282c;
}
.cmn_Capcmn_Cap {
  text-transform: capitalize;
}

/* ADD BTN DIV */
.commomotherbtnadd {
  justify-content: left;
  display: flex;
  margin-top: 25px;
}

/* User  */
.heading {
  color: #6b6b6b !important;
  font-size: 23px;
  font-weight: 600;
  margin-left: -31px;
}
.closeicon {
  margin-top: 10px;
  color: #6b6b6b;
  padding-left: 59px;
}
.closeicon:hover {
  color: #e50000;
  cursor: pointer;
}

.commombtnadd {
  justify-content: right;
  display: flex;
}
.commombtnaddType {
  justify-content: right;
  display: flex;
  width: 90%;
}
.commombtnaddsubsection {
  justify-content: right;
  display: flex;
  width: 110%;
  margin-top: 29px;
}
.commombtnaddsection {
  justify-content: right;
  display: flex;
  width: 102.5%;
  margin-top: 9px;
}
.commombtncountrycurrency {
  justify-content: right;
  display: flex;
  width: 94%;
  margin-top: 19px;
}
.commombtn-submit {
  /* background-color:  #06851d; */
  background-color: #fff;
  border-color: #06851d;
  border-radius: 50px;
  color: #6b6b6b;
  box-shadow: 0 0 0 0 rgb(65 181 222 / 2%) !important;

  margin-bottom: 25px;
  margin-left: 72%;
}
.commombtn-submit:hover {
  background-color: #06851d;
  color: #fff;
  border-color: #06851d;
  box-shadow: 0 0 0 0 rgb(65 181 222 / 2%) !important;
}
.commombtn-submit:active {
  background-color: #06851d !important;
  color: #fff;
  border-color: #06851d !important;
  box-shadow: 0 0 0 0 rgb(65 181 222 / 2%) !important;
}

.user-form {
  /* width: 97% !important; */
}
.usr-field:focus {
  color: #5c6873;
  background-color: #fff;
  border-color: #06851d;
  outline: 0;
}
.ViewIcon {
  /* background-color:  #06851d; */
  padding-bottom: 5px;
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 7px;
  margin-right: 5px;
  color: #516171;
  font-size: 16px;
}
.EditIcon {
  background-color: #27a5d6;
  padding-bottom: 5px;
  padding-top: 5px;
  padding-left: 6px;
  padding-right: 4px;
  border-radius: 7px;
  margin-right: 7px;
  color: #fff;
}
.DeleteIcon {
  background-color: #c20a0a;
  padding-bottom: 5px;
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 7px;
  color: #fff;
}
.revisionIcon {
  background-color: #eeb805;
  padding-bottom: 5px;
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 7px;
  color: #fff;
}
.form-control:focus {
  color: #5c6873;
  background-color: #fff;
  border-color: #6b6b6b56;
  outline: 0;
}
.form-control:not(.email) {
  display: block;
  /* width: 100%; */
  /* height: calc(1.5em + 0.75rem + 2px); */
  /* padding: 0.375rem 0.75rem; */
  padding-top: 0.375rem;
  padding-right: 2rem;
  padding-bottom: 0.375rem rem;
  padding-left: 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #5c6873;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e4e7ea;
  border-radius: 0.25rem;
  /* text-transform: capitalize; */
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s;
}
/* .row {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0px;
  margin-left: 0px;
} */
.userfirstsectn {
  background-color: #06851d;
  border-top-left-radius: 0.8rem;
  border-bottom-left-radius: 0.8rem;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: none !important;
  border-radius: 0.8rem;
  outline: 0;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 15px;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 0px;
  padding: 1rem;
  border-top: 1px solid #c8ced3;
  border-bottom-right-radius: 83.3rem;
  border-bottom-left-radius: 73.3rem;
}

.commonbasicdetailscontname {
  font-weight: 600;
  font-size: 16px;
  color: #707070;
  margin: 0px;
  margin-top: 10px;
  text-transform: capitalize !important;
  /* font-family: GilroyBold, "sans-serif" !important; */
}
.commonbasicdetailscontper {
  color: #000000ba;
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  font-family: GilroyBold, "sans-serif" !important;
}

.commonInput input {
  padding: 0px 2px 0px 33px;
  border: 1px solid #0000004d;
  outline: none;
  box-shadow: none;
  position: relative;
  text-align: left;

  color: #23282c;
  width: 90%;
  height: 39px !important;

  border-radius: 30px;
}

.cmnSearchIcon {
  color: #9b9da0;
  position: relative;
  z-index: 50;
  left: 26px;
  bottom: 1px;
}

/* change for responsive--- */

.csrdSerchInpt1 input {
  padding: 0.5rem 1.5rem;
  border: 1px solid transparent;
  outline: none;
  border-radius: 10px;
  border-radius: 30px;
  padding-left: 0px;
}
.usrAlgn .csrdSerchInpt1 input {
  width: 305px;
}

.csrdSerchInpt1 {
  border: 1px solid #0000004d;
  display: inline-flex;
  border-radius: 30px;
  margin-right: 23px;
  height: 38px;
}
.csrdFilterInpt1 {
  /* padding-top: 10px; */
}
.serchNFilterDiv {
  display: inline-flex;
}

.csrdSerchInpt1 input .focus-visible {
  border: 1px solid transparent;
}
.cmnfilter1 {
  width: 115px;
  height: 38px;
  float: left;
  /* margin-top: 10px; */
  color: #06851d;
  font-size: 17px;
  cursor: pointer;
  /* border: 1px solid green; */
  /* width: 40%; */
  border-radius: 30px;
  box-shadow: 1px 0px 7px 1px rgba(0, 0, 0, 0.233);
  border: 1px solid #06851d;
  padding-top: 10px;
  padding-left: 17px;
  height: 38px;
}

.cmnfilter1::after {
  content: "Filter..";
  font-size: 17px;
  padding-left: 12px;

  color: #0c0c0ced;
  font-family: var(--font-family-sans-serif);
  font-weight: bolder;
}

/* ---------------------- */

.cmnHdName {
  font-size: 24px;
  /* font-family: Roboto, sans-serif; */
  color: #06851d;
  font-weight: 600;
  margin-top: 13px;
}
.createprjct-btn {
  /* font-family: "Montserrat", sans-serif; */
  text-align: center;
  color: white;
  background-color: #06851d !important;
  border: 1px solid #06851d !important;
  border-radius: 3px !important;
  width: 70%;
  padding-bottom: 8px;
  padding-top: 8px;
  font-size: 14px;
  box-shadow: 0 0 0 0.2rem rgba(65, 181, 222, 0) !important;
  font-weight: 500;
  margin-top: -33px;
  /* position: relative; */
  /* top: -27px; */
  margin-left: 100px;
}

.createprjct-btn:hover {
  /* font-family: "Montserrat", sans-serif; */
  text-align: center;
  color: white;
  background-color: #06851d !important;
  border: 1px solid #06851d !important;
  border-radius: 3px !important;
  width: 70%;
  padding-bottom: 8px;
  padding-top: 8px;
  font-size: 14px;
  box-shadow: 0 0 0 0.2rem rgba(65, 181, 222, 0) !important;
  font-weight: 500;
}
.createprjct-btn:active {
  /* font-family: "Montserrat", sans-serif; */
  text-align: center;
  color: white;
  background-color: #06851d !important;
  border: 1px solid #06851d !important;
  border-radius: 3px !important;
  width: 70%;
  padding-bottom: 8px;
  padding-top: 8px;
  font-size: 14px;
  box-shadow: 0 0 0 0.2rem rgba(65, 181, 222, 0) !important;
  font-weight: 500;
}
/* ========================nav============================ */
.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link.active {
  color: #06851d !important;
  background: white;
  border-color: white;
  /* padding-left: 20px;
  padding-right: 20px; */
  width: 185px;
  text-align: left;
  border-bottom: 3px solid #06851d !important;
  padding-bottom: 20px;
}
.nav-tabs .nav-link.active:focus {
  color: #06851d !important;
  background: white;
  border-color: white;
  /* padding-left: 20px;
  padding-right: 20px; */
  width: 185px;
  text-align: left;
  border-bottom: 3px solid #06851d;
  padding-bottom: 20px;
}
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  color: #06851d !important;
  border-color: white;
  padding-bottom: 20px;
}
.nav-tabs .nav-link {
  border: 1px solid transparent;
  background: white;
  border-color: white;
  /* padding-left: 20px;
  padding-right: 20px; */
  width: 185px;
  text-align: left;
  color: #092032;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 0px !important;
  padding-bottom: 20px;
}
.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 945;
}
/* ================================================================ */

.projectCardDiv {
  margin-top: 5%;
  padding: 0px 110px;
}

.subnavbar .navbar-nav .nav-link:active {
  text-decoration: none !important;
}

/* ===================navbar============================================ */

.bg-light {
  background-color: #06851d !important;
  margin-top: 80px;
  padding-bottom: 13px;
}
.subnavbar .navbar-nav .nav-link {
  color: white !important;
  font-size: 14px;
  font-weight: 900;
}
.subnavbar .navbar-nav {
  display: flex;
  padding-left: 75px;
  margin-bottom: 0;
  list-style: none;
}
.subnavbar .navbar-nav .nav-link:hover {
  color: white !important;
  text-decoration: underline;
}
.projectlabel {
  margin-top: 25px;
  line-height: 1.7;
  color: #516171;
  text-transform: capitalize;
}
.simplifiedLabel {
  font-weight: 600;
  font-size: 14px;
  color: #707070;
  margin: 0px;
  margin-top: 0px;
  text-transform: capitalize !important;
}
.simplifiedValue::before {
  content: ":";
  position: absolute;
  left: 2px;
  font-weight: 700;
  color: #707070;
}

.projectlabel1 {
  margin-top: 25px;
  line-height: 1.7;
  color: #516171;
  text-transform: capitalize;
  font-weight: 600;
}
/* =======================form==================================== */
.userModalDiv .form-control {
  width: 80%;
  border: 1px solid rgb(153, 152, 152);
  border-radius: 3px;
  /* padding: 0 20px; */
  background-color: #fff;
  font-family: "Hind", sans-serif;
  font-size: 14px;
  color: #516171;
  text-transform: capitalize;
  text-overflow: ellipsis;
}
.mb-2,
.my-2 {
  margin-bottom: 10px !important;
}
.projectinformtnhd {
  color: #06851d;
  font-size: 18px;
  font-weight: 600;
  margin-left: 12px;
}
.projectinformtnsctn {
  margin-top: 3%;
}
.cardoverlay:hover .overlay {
  opacity: 1;
}
/* ==========================calculationpage================== */
.calculateprojectsectionhd {
  color: #000000a3;
  background-color: transparent;
  border-color: transparent transparent #f3f3f3;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: -10px;
}

.calculationDiv .form-control {
  width: 95%;
  border: 1px solid rgb(153, 152, 152);
  border-radius: 3px;
  padding: 0 10px;
  background-color: #fff;
  font-family: "Hind", sans-serif;
  font-size: 14px;
  color: #516171;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.operationhd {
  font-size: 14px;
  font-weight: 600;
  margin-left: 15px;
}
.calculation-submit-btn {
  background-color: #06851d;
  /* width: 35%; */
  padding-bottom: 5px;
  padding-top: 5px;
  border-radius: 63px;
  color: white;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  z-index: 5;
  transition: all 0.4s ease-in-out;
  border: 2px solid transparent;
  overflow: hidden;
}

.calculation-prev-btn {
  background-color: gray;
  width: 35%;
  padding-bottom: 5px;
  padding-top: 5px;
  border-radius: 63px;
  color: white;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  z-index: 5;
  transition: all 0.4s ease-in-out;
  border: 2px solid transparent;
  overflow: hidden;
}
/* =====================DESCRIPTION======================= */
.projectinformtnsctn .card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0px solid #c8ced3;
  border-radius: 0.25rem;
  /* width: 18rem; */
  height: 150px;
  box-shadow: 2px 4px 8px 4px rgba(46, 61, 73, 0.2);
  cursor: pointer;
}

/* .projectinformtnsctn .card:hover {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: red;
  background-clip: border-box;
  border: 0px solid #c8ced3;
  border-radius: 0.25rem;
  height: 150px;
  box-shadow: 2px 4px 8px 4px rgba(46, 61, 73, 0.2);
  cursor: pointer;
} */

.card-subtitle {
  margin-bottom: 0;
  text-align: center;
  font-size: 14px;
  font-weight: 700 !important;
}
.segmentsimages {
  justify-content: center;
  align-items: center;
  display: flex;
  margin: auto;
}
.description-submit-btn {
  background-color: #06851d !important;
  border-color: #06851d !important;
  width: 100px;
  padding-bottom: 5px;
  padding-top: 5px;
  border-radius: 63px !important;
  color: white !important;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  z-index: 5;
  transition: all 0.4s ease-in-out;
  border: 2px solid transparent;
  overflow: hidden;
  margin-left: 10px;
  font-weight: 600;
}
.description-submit-btn1 {
  background-color: #5a5a5a !important;
  border-color: #3b3d3b !important;
  width: 100px;
  padding-bottom: 5px;
  padding-top: 5px;
  border-radius: 63px !important;
  color: white !important;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  z-index: 5;
  transition: all 0.4s ease-in-out;
  border: 2px solid transparent;
  overflow: hidden;
  margin-left: 10px;
  font-weight: 600;
}

@media screen and (min-width: 768px) and (max-width: 886px) {
  .description-submit-btn {
    margin-left: 3px;
  }
}
.description-submit-btn:hover {
  color: #fff;
  background-color: #06851d !important;
  border-color: #06851d !important;
  box-shadow: 0 0 0 0rem rgba(65, 181, 222, 0) !important;
}
.description-submit-btn:active {
  color: #fff;
  background-color: #06851d !important;
  border-color: #06851d !important;
  box-shadow: 0 0 0 0rem rgba(65, 181, 222, 0) !important;
}

.description-prev-btn {
  background-color: white !important;
  padding-bottom: 5px;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 5px;
  border-radius: 63px !important;
  color: #000000bf !important;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  z-index: 5;
  transition: all 0.4s ease-in-out;
  border: 2px solid #06851d !important;
  overflow: hidden;

  font-weight: 600;
  box-shadow: 0 0 0 0rem rgba(65, 181, 222, 0) !important;
}
.description-prev-btn:hover {
  color: #000000bf;
  background-color: white;
  border-color: #06851d;
  box-shadow: 0 0 0 0rem rgba(65, 181, 222, 0) !important;
}
.description-prev-btn:active {
  color: #000000bf;
  background-color: white;
  border-color: #06851d;
  box-shadow: 0 0 0 0rem rgba(65, 181, 222, 0) !important;
}
@media screen and (min-width: 768px) and (max-width: 856px) {
  .des-prev-only {
    padding-right: 2.5rem !important;
  }
}

/* ======================================================= */
.operprevbtn {
  float: right;
  margin-right: 20px;
}
@media screen and (min-width: 768px) and (max-width: 1075px) {
  .operprevbtn {
    margin-top: 10px;
  }
}
.operation-submit-btn {
  background-color: #06851d;

  padding-bottom: 5px;
  padding-top: 5px;
  border-radius: 63px;
  color: white;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  z-index: 5;
  transition: all 0.4s ease-in-out;
  border: 2px solid transparent;
  overflow: hidden;
  float: right;
  font-weight: 600;
  box-shadow: 0 0 0 0rem rgba(65, 181, 222, 0) !important;
}
.operation-submit-btn:hover {
  color: #fff;
  background-color: #06851d;
  border-color: #06851d;
  box-shadow: 0 0 0 0rem rgba(65, 181, 222, 0) !important;
}
.operation-submit-btn:active {
  color: #fff;
  background-color: #06851d !important;
  border-color: #06851d !important;
  box-shadow: 0 0 0 0rem rgba(65, 181, 222, 0) !important;
}

.generaterprtbtn {
  width: 160px;
}
.gnrtrprtmain {
  display: flex;
  justify-content: end;
}
@media screen and (min-width: 768px) and (max-width: 966px) {
  .gnrtrprtmain,
  .gnrtrprtprev {
    display: block;
    margin-bottom: 11px;
    /* margin-left: 4px; */
    left: 20px;
    position: relative;
    width: 111px;
  }
}
.graphhd {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  margin-top: 35px;
  margin-bottom: 15px;
  padding-left: 10px;
  padding-right: 10px;
}
.graphsection {
  border: 1px solid #80808057;
  margin-left: -1px;
  margin-top: 25px;
}
.frstgraphsection {
  border: 1px solid #80808057;
  margin-top: 25px;
  height: 550px;
}
.frstgraphsection1 {
  border: 1px solid #80808057;
  margin-left: -10px;
  margin-top: 25px;
  height: 550px;
}

.graphsecsection {
  border: 1px solid #80808057;
  margin-left: -10px;
  margin-top: 25px;
  margin-bottom: 25px;
}
.text-muted:hover {
  color: #06851d !important;
}
.text-muted:active {
  color: #06851d !important;
}
/* ================admin====================== */
.plus-icon-outlined {
  overflow: hidden;
  vertical-align: middle;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin-bottom: 1.5px;
  margin-right: 8px;
}
.cmnsViewIcon {
  background-color: #06851d;
  padding-bottom: 5px;
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 7px;
  margin-right: 6px;
  color: #fff;
}
.userbtn-submit {
  background-color: #fff;
  border-color: #06851d;
  border-radius: 30px;
  color: #6b6b6b;
  box-shadow: 0 0 0 0 rgb(65 181 222 / 2%) !important;
  margin-right: 29px;
  margin-bottom: 28px;
  margin-top: 3%;
}

.userbtn-cancel {
  background-color: #fff;
  border-color: #363a36a8;
  border-radius: 30px;
  color: #6b6b6b;
  box-shadow: 0 0 0 0 rgb(65 181 222 / 2%) !important;
  margin-right: 20px;
  margin-bottom: 28px;
  margin-top: 3%;
  width: 90px;
}
.userbtn-cancel:hover {
  background-color: #424040cb;
  border-color: #2c2b2b81;
  color: #fff;
}
.userbtn-submit:hover {
  background-color: #06851d;
  color: #fff;
  border-color: #06851d;
  box-shadow: 0 0 0 0 rgb(65 181 222 / 2%) !important;
}
.userbtn-submit:active {
  background-color: #06851d !important;
  color: #fff;
  border-color: #06851d !important;
  box-shadow: 0 0 0 0 rgb(65 181 222 / 2%) !important;
}
.userbtn-submit:hover .user-submitbtn {
  color: white;
}

.user-submitbtn {
  overflow: hidden;
  vertical-align: middle;
  margin-right: 4px;
  font-size: 15px;
}
.useradd-btn.btn-primary,
.useradd-btn.btn-primary:active {
  margin-right: 13px;
  color: #707070;
  background-color: white;
  border-color: #06851d;
  border-radius: 30px;
  width: 11%;
  padding-bottom: 8px;
  padding-top: 8px;
  box-shadow: 0 0 0 0 rgba(65, 181, 222, 0.5);
}
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #06851d;
  border-color: #06851d;
  border-radius: 30px;
}
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none !important;
  border-radius: 30px;
}

/* .useradd-btn.btn-primary:hover {
  margin-right: 13px;
  border-color:  #06851d;
  width: 11%;
  padding-bottom: 8px;
  padding-top: 8px;
  color: white;
  border-radius: 10px;
  background-color:  #06851d;
  box-shadow: 0 0 0 0 rgb(65 181 222 / 0%) !important;
  transition: color 0.1s ease-in-out, background-color 0.5s ease-in-out,
    border-color 0.5s ease-in-out, box-shadow 0.5s ease-in-out;
} */

/* .useradd-btn.btn-primary:hover .useradd-btn-icon {
  position: inherit;
  z-index: 2;
  border-radius: 10px;
  margin-right: 13px;
  color: white;
  transition: color 0.1s ease-in-out, background-color 0.5s ease-in-out,
    border-color 0.5s ease-in-out, box-shadow 0.5s ease-in-out;
} */
.useradd-btn.btn-primary:hover {
  background-color: #06851d;
  color: white;
}
.useradd-btn.btn-primary:hover .useradd-btn-icon {
  color: #ebf5ed !important;
}

.useradd-btn.btn-primary:active .useradd-btn-icon {
  color: white !important;
}

.useradd-btn-icon {
  /* margin-right: 13px; */
  color: #06851d;
  font-size: 1.2rem;
}

.useradd-btn-name {
  vertical-align: text-top;
  margin-left: 4px;
}
.usr-side-img {
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  position: relative;
  top: 20%;
}
.commonbasicdetails-user {
  display: block;
  width: 100%;
  /* height: calc(1.5em + 0.75rem + 2px); */
  padding-top: 8px;
  padding-left: 8px;
  padding-bottom: 8px;
  font-size: 14px;
  font-weight: 400;
  color: #5c6873;
  background-color: hwb(168deg 89% 8% / 91%);
  background-clip: padding-box;
  border-radius: 0.25rem;
  overflow: auto;
  margin-bottom: 20px;
}
.usr-callingcode {
  display: inline-block;
  height: calc(1.5em + 0.75rem + 2px);
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 14px;
  font-weight: 400;
  color: #5c6873;
  background-color: hwb(168deg 89% 8% / 91%);
  border-radius: 0.25rem;
  /* overflow: auto; */
  margin-bottom: 20px;
  width: 85px;
}
.mobnum {
  width: 84%;
  margin-left: 5px;
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0px;
  margin-left: 0px;
}
/* .commonbasicdetailsfrstcontname2 {
  font-size: 14px;
  color: #707070;
  margin: 0px;
  margin-top: 0px;
  margin-top: 0px;
  text-transform: capitalize !important;
  line-height: 2rem;
} */
.usr-callingcode-col {
  display: flex;
}
/* ===========commominput================== */

.projectcommonInput {
  width: 100% !important;
}

.projectcommonInput i {
  position: absolute;
}

.projectcommonInput input {
  padding: 0.1rem 1.5rem;
  border: 0px solid #0000004d;
  outline: none;
  box-shadow: none;
  text-align: left;
  color: black;
  width: 100% !important;
  height: 35px !important;
  border-bottom: 1px solid #8080805c;
  font-size: 14px;
}
.projectcmnSearchIcon {
  margin-top: 11px !important;
  margin-left: -40px !important;
  min-width: 40px;
  color: #98a7b7;
}
/* .commonInput {
  width: 100% !important;
  margin-left: 30px;
  margin-top: -13px;
  height: 37px;
} */
/* .commonInput i {
 
  position: relative;
  bottom: 1.5rem;
  z-index: 2;
  left: 5.9rem;
} */

.global-class-name {
  margin-top: 7px;
  color: #bbb1b1;
  font-size: 20px;
  line-height: 0.75em;

  position: absolute;
  left: 79px;
}

/* RESET PASSWORD */
.resetheaderelmnts {
  width: 93%;
  margin: auto;
}
/* RESET BUTTON */
.btn-danger:not(.cmnDelCnclBtn) {
  color: #fff;
  background-color: #06851d !important;
  width: 50%;
  margin: auto;
  border-color: #06851d !important;
}
.btn-danger:hover:not(.cmnDelCnclBtn) {
  color: #fff;
  background-color: #06851d !important;
  border-color: #06851d !important;
}
.btn-danger:focus {
  box-shadow: none !important;
}

.editprofclose {
  display: flex;
  justify-content: end;
  position: relative;
}

.resetbtncentering {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  width: 80%;
}

/* Reset close */
.close-position-reset {
  position: absolute;
  left: 97%;
}
.global-class-name:hover {
  color: red;
}
.card-body:not(.cardbdyfilter, .hlpCardBody) {
  flex: 1 1 auto;
  left: 45px !important;

  position: relative !important;
  padding: 1.25rem;
}
.card-body {
  flex: 1 1 auto;
  position: relative !important;
  padding: 1.25rem;
}
.cardbdyfilter {
  flex: 1 1 auto;
  padding: 1.25rem;
  width: 138%;
  right: 148px;
  position: relative;
  border: 1px solid rgba(83, 79, 79, 0.452);
  border-radius: 10px;
  bottom: -14px;
}
.filtercard {
  border: none !important;
}
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #06851d !important;
}
.button:focus {
  outline: 1px dotted !important;
  box-shadow: none !important;
  /* outline: 5px auto -webkit-focus-ring-color; */
}
button:focus-visible {
  outline: none !important;
}

.global-class-name:hover:not(.close-position-reset) {
  /* margin-top: 7px;

  font-size: 20px;
  line-height: 0.75em; */

  /* position: absolute; */
  /* left: 60px; */
  color: #f82500d2;
  cursor: pointer;
}

.global-class-name-lg {
  color: #bbb1b1;
  font-size: 20px !important;
  line-height: 0.75em;
  position: absolute;
  left: 60px;
}
.global-class-name-lg:hover {
  font-size: 20px;
  line-height: 0.75em;

  position: absolute;
  /* left: 72px; */
  color: #f82500d2;
  cursor: pointer;
}
.close-positiondel {
  margin-top: 10px;
  color: #e0d1d1;
  font-size: 1.33333333rem;
  line-height: 0.75em;
  vertical-align: middle;

  position: absolute;
  right: 14px;
}
.close-positiondel:hover {
  color: rgba(255, 0, 0, 0.596);
}

.masterDataCardDiv {
  margin-top: 12px;
  padding: 7px 70px;
}
.masterDataCardDiv .card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #c8ced3;
  border-radius: 10px;
  margin-top: 3px;
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 650px;
    margin: 1.75rem auto;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

/*.....................Pagination..............................*/
.paginationDiv {
  justify-content: center;
  display: flex;
  margin-top: -15px;
}

.paginationDiv .pagination {
  margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none;
}

.paginationDiv .pagination > .active > a {
  background-color: #06851d;
  border-color: #06851d;
  color: #fff;
}

.paginationDiv .pagination > li > a {
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
  margin: 7px;
}
/* .paginationDiv .pagination > li > a:hover {
  color:  #06851d !important;
} */
.paginationDiv .pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #06851d;
  border-radius: 4px;
  outline: none;
}

.paginationDiv .pagination > li > a,
.pagination > li > span {
  color: #0f0f0f;
}

.paginationDiv .pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: unset;
}

.paginationDiv .pagination > .disabled > a {
  color: #080808 !important;
  cursor: default;
}

/* DETAILED VIEW */
.commonbasicdetailssecondcontname {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  color: #5c6873;
  background-color: #fff;
  background-clip: padding-box;
  /* border: 1px solid #e4e7ea;
  border-radius: 0.25rem; */
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  /* overflow: auto; */
  height: auto;
  text-transform: capitalize;
  text-align: justify;
  word-break: break-word;
  /* text-align: center; */
}

.commonbasicdetailsfrstcontname2 {
  /* font-size: 14px;
  color: #707070;
  margin: 0px;
  margin-top: 0px;
  text-transform: capitalize !important;
  line-height: 2rem; */

  font-size: 14px;
  color: #707070;
  margin: 0px;
  margin-top: 0px;
  text-transform: capitalize !important;
  line-height: 2rem;
  width: 102%;
}
.commonbasicdetailsfrstcontname2::after {
  content: ":";
  position: absolute;
  right: 2px;
  font-weight: 700;
}

/* Misc btn */
.misc-btn {
  width: 40% !important;
  margin-left: 30px;
}
.commonbasicdetails-user:not(.email) {
  text-transform: capitalize;
}

/*  */
@media (min-width: 992px) {
  .sidebar-minimized .sidebar .nav-item:hover > .nav-link {
    background: #06851d !important;
  }
}
@media (min-width: 992px) {
  .sidebar-minimized .sidebar .nav-link:hover {
    width: 350px;
    background: #06851d;
    color: white;
  }
}

.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #06851d !important;
}
/* search add button */
.projectinformtnsctn .card-body {
  flex: 1 1 auto;
  left: 0px !important;
  position: relative !important;
  padding: 1.25rem;
}
.typeheadstyle {
  margin-top: 20px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-shadow: 0 0 0 0 rgba(32, 168, 216, 0.25) !important;
}
.dateformatetext {
  width: 80%;
  border: 1px solid rgb(153, 152, 152) !important;
  border-radius: 3px;
  padding: 0px 15px;
  background-color: #fff;
  font-family: "Hind", sans-serif;
  font-size: 14px;
  color: #516171;
  /* text-transform: capitalize; */
  height: calc(1.5em + 0.75rem + 2px);
  font-weight: 400;
  line-height: 1.5;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  display: block;
}
.dateformatetext:focus-visible {
  width: 80%;
  border: 1px solid rgb(153, 152, 152) !important;
  border-radius: 3px;
  padding: 0px 15px;
  background-color: #fff;
  font-family: "Hind", sans-serif;
  font-size: 14px;
  color: #516171;
  /* text-transform: capitalize; */
  height: calc(1.5em + 0.75rem + 2px);
  font-weight: 400;
  line-height: 1.5;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  display: block;
  outline: 0px solid gray !important;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 930;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 14px !important;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.dropdown-item {
  position: relative;
  padding: 5px 15px !important;
  border-bottom: 0px solid #c8ced3 !important;
  white-space: inherit;
}
.form-control:focus {
  outline: 0px solid gray;
  box-shadow: 0 0 0 0.2rem rgb(32 168 216 / 0%);
}
.form-control:focus-visible {
  outline: 0px solid gray !important;
}
@media (min-width: 576px) {
  .modal-sm {
    max-width: 415px;
  }
}
.textareaClass {
  margin-top: 10px;
  line-height: 1.7;
  color: #516171;
  text-transform: capitalize;
  font-size: 14px;
  margin-bottom: -10px;
}
.unitlabel {
  margin-bottom: 0px !important;
  margin-top: 14px;
  text-transform: capitalize;
}
.spltype {
  position: absolute;
  top: -17px !important;
}
.secndtypeheadstyle {
  margin-top: 24px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-shadow: 0 0 0 0 rgba(32, 168, 216, 0.25) !important;
  /* margin-bottom: 30px; */
}
.secndtypeheadstyle1 {
  margin-top: 30px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-shadow: 0 0 0 0 rgba(32, 168, 216, 0.25) !important;
  margin-bottom: 30px;
}
.calculationDiv .secndtypeheadstyle .form-control {
  width: 95%;
  border: 1px solid rgb(153, 152, 152);
  border-radius: 3px;
  padding: 0 20px;
  background-color: #fff;
  font-family: "Hind", sans-serif;
  font-size: 14px;
  color: #516171;
  /* padding-bottom: 15px; */
  padding-bottom: 18px;
  padding-top: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 10px !important;
}
.calculationDiv .secndtypeheadstyle1 .form-control {
  width: 95%;
  border: 1px solid rgb(153, 152, 152);
  border-radius: 3px;
  padding: 0 20px;
  background-color: #fff;
  font-family: "Hind", sans-serif;
  font-size: 14px;
  color: #516171;
  /* padding-bottom: 15px; */
  padding-bottom: 19px;
  padding-top: 18px;
  margin-bottom: 10px !important;
}
.dropdown-menu.show {
  position: absolute;
  display: block;
  max-height: 300px;
  overflow: auto;
  will-change: transform;
  /* width: 272.125px !important; */
  top: 0px;
  left: 0px;
  transform: translate3d(0px, 38px, 0px);
  margin-left: -1px;
}
.calculationtabforms .table {
  width: 95%;
  margin-bottom: 1rem;
  color: #23282c;
  border: 1px solid #80808063;
}
.calculationtabforms .table thead th {
  text-align: left;
  vertical-align: bottom;
  background-color: #06851d;
  color: white;
  font-size: 14px;
}
.operationhd1 {
  font-size: 14px;
  font-weight: 600;
  margin-left: 15px;
  margin-bottom: 25px;
  margin-top: 20px;
}

/* -------------temp style */

.padding-bt-5 {
  padding-bottom: 5px;
}
.padding-tp-5 {
  padding-top: 5px;
}
.padding-lt-10 {
  padding-left: 10px;
}
.pull-left {
  float: left !important;
}

.demoCont {
  display: inline-flex;
}

.demoLabel {
  float: left;
  font-size: 15px;
  padding-right: 15px;
}

/* default switch button style starts */

.ts-helper {
  position: relative;
  display: block;
  height: 15px;
  width: 32px;
  background: #898989;
  border-radius: 100px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.ts-helper:after {
  position: absolute;
  left: -6px;
  top: -3px;
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 100px;
  background: #fafafa;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28);
  content: "";
  transition: all 0.3s ease;
}

.ts-helper:active:after {
  transform: scale(1.15, 0.85);
}

.ts-input {
  visibility: hidden;
}

.ts-input:checked ~ label {
  background: #7edafe;
}

.ts-input:checked ~ label:after {
  left: 20px;
  background: #04acec;
}

.ts-input:disabled ~ label {
  background: #d5d5d5;
  pointer-events: none;
}

.ts-input:disabled ~ label:after {
  background: #bcbdbc;
}

/* default switch button style ends */

/* custom swtich button 1 style */

.cusSwtich input[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
}

.cusSwtich label {
  cursor: pointer;
  text-indent: -9999px;
  width: 130px;
  height: 60px;
  background: grey;
  display: block;
  border-radius: 100px;
  position: relative;
}

.cusSwtich label:after {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  width: 50px;
  height: 50px;
  background: #fff;
  border-radius: 90px;
  transition: 0.3s;
}

.cusSwtich input:checked + label {
  background: #bada55;
}

.cusSwtich input:checked + label:after {
  left: calc(100% - 5px);
  transform: translateX(-100%);
}

.cusSwtich label:active:after {
  width: 130px;
}

/* custom style 2 */
.react-switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.react-switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 100px;
  height: 32px;
  background: grey;
  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;
}

.react-switch-label .react-switch-button {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  width: 30px;
  height: 30px;
  border-radius: 45px;
  transition: 0.2s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.react-switch-checkbox:checked + .react-switch-label .react-switch-button {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.react-switch-label:active .react-switch-button {
  width: 11px;
}
.helpTglBtnAlign {
  width: fit-content;
  margin: auto;
}
.oprtndescription-prev-btn {
  background-color: white;
  width: 30%;
  padding-bottom: 5px;
  padding-top: 5px;
  border-radius: 63px;
  color: #000000bf;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  z-index: 5;
  transition: all 0.4s ease-in-out;
  border: 2px solid #06851d;
  overflow: hidden;
  /* float: right; */
  /* margin-right: 80px; */
  font-weight: 600;
  box-shadow: 0 0 0 0rem rgba(65, 181, 222, 0) !important;
}
.oprtndescription-prev-btn:hover {
  color: #000000bf;
  background-color: white;
  border-color: #06851d;
  box-shadow: 0 0 0 0rem rgba(65, 181, 222, 0) !important;
}
.oprtndescription-prev-btn:active {
  color: #000000bf;
  background-color: white;
  border-color: #06851d;
  box-shadow: 0 0 0 0rem rgba(65, 181, 222, 0) !important;
}
.oprtndescription-submit-btn {
  background-color: #06851d;
  /* width: 35%; */
  padding-bottom: 5px;
  padding-top: 5px;
  border-radius: 63px;
  color: white;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  z-index: 5;
  transition: all 0.4s ease-in-out;
  border: 2px solid transparent;
  overflow: hidden;
  float: right;
  margin-right: 80px;
  font-weight: 600;
  box-shadow: 0 0 0 0rem rgba(65, 181, 222, 0) !important;
}
.oprtndescription-submit-btn:hover {
  color: #fff;
  background-color: #06851d;
  border-color: #06851d;
  box-shadow: 0 0 0 0rem rgba(65, 181, 222, 0) !important;
}
.oprtndescription-submit-btn:active {
  color: #fff;
  background-color: #06851d !important;
  border-color: #06851d !important;
  box-shadow: 0 0 0 0rem rgba(65, 181, 222, 0) !important;
}
.infoastrk {
  color: red;
  font-size: 14px;
  font-weight: 900;
}
.valuecalculation {
  font-weight: 900;
  font-size: 14px;
}
/* ===================nav==================================== */

.projectCalculationTab {
  margin-top: 5%;
  padding: 0px 95px;
}

/* .projectCalculationTab .nav-tabs .nav-link.active {
  color: white !important;
  background: #06851d !important;
  border-color: white;
  width: 185px;
  text-align: center;
  border-bottom: 3px solid #06851d !important;
  padding-bottom: 0px !important;
}
.projectCalculationTab .nav-tabs .nav-link {
  border: 1px solid transparent;
  background: white;
  border-color: white;
  width: 185px;
  text-align: center;
  color: #092032;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 0px !important;
 
} */
.navbar-light .navbar-text {
  white-space: nowrap !important;
  color: white !important;
  font-size: 14px !important;
  font-weight: 900 !important;
  margin-right: 100px !important;
}
.calculateprojectsectionhdnxt {
  color: #000000a3;
  background-color: transparent;
  border-color: transparent transparent #f3f3f3;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: -10px;
  margin-left: 15px;
}
.referece-btn {
  /* font-family: "Montserrat", sans-serif; */
  text-align: center;
  color: white;
  background-color: #06851d !important;
  border: 1px solid #06851d !important;
  border-radius: 3px !important;
  /* width: 100%; */
  padding-bottom: 8px;
  padding-top: 8px;
  font-size: 14px;
  box-shadow: 0 0 0 0.2rem rgba(65, 181, 222, 0) !important;
  font-weight: 500;
  margin-bottom: 20px;
  margin-top: 25px;
  float: right;
}

.referece-btn:hover {
  /* font-family: "Montserrat", sans-serif; */
  text-align: center;
  color: white;
  background-color: #06851d !important;
  border: 1px solid #06851d !important;
  border-radius: 3px !important;
  /* width: 100%; */
  padding-bottom: 8px;
  padding-top: 8px;
  font-size: 14px;
  box-shadow: 0 0 0 0.2rem rgba(65, 181, 222, 0) !important;
  font-weight: 500;
  margin-bottom: 20px;
  margin-top: 25px;
  float: right;
}
.referece-btn:active {
  /* font-family: "Montserrat", sans-serif; */
  text-align: center;
  color: white;
  background-color: #06851d !important;
  border: 1px solid #06851d !important;
  border-radius: 3px !important;
  /* width: 100%; */
  padding-bottom: 8px;
  padding-top: 8px;
  font-size: 14px;
  box-shadow: 0 0 0 0.2rem rgba(65, 181, 222, 0) !important;
  font-weight: 500;
  margin-bottom: 20px;
  margin-top: 25px;
  float: right;
}
.tabforms .row {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0px;
  margin-left: 0px;
}
.nxtcolum {
  border-right: 1px solid #8080809c;
  padding-right: 20px;
}

.cmnSaveBtn {
  width: 100px;
  color: white;
  border-radius: 50px !important;
  background-color: #06851d !important;
  border-color: #06851d !important;
}

.cmnSaveBtn:hover {
  background-color: #06851d !important;
  border-color: #06851d !important;
  color: white !important;
  border-radius: 50px;
}

.cmnSaveBtn:active {
  background-color: #06851d !important;
  border-color: #06851d !important;
  color: white !important;
}

.cmnSaveBtn:focus {
  border-color: #06851d !important;
  background-color: #06851d !important;
  outline: 0;
  box-shadow: 0 0 0 0rem #06851d !important;
}

.projectViewCardDiv .nav-tabs {
  background-color: #06851d !important;
}
.projectViewCardDiv .nav-tabs .nav-link {
  border: 1px solid transparent;
  background: #06851d !important;
  border-color: #06851d !important;
  width: 185px;
  text-align: center;
  color: #092032;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 0px !important;
  padding-bottom: 20px;
  color: white !important;
}
.projectViewCardDiv .nav-tabs .nav-link.active {
  color: white !important;
  background: white;
  border-color: #06851d !important;
  width: 185px;
  text-align: center;
  border-bottom: 3px solid white !important;
  padding-bottom: 20px;
  /* background-color: red; */
  background: #06851d !important;
}
.projectViewCardDiv {
  margin-top: -1%;
  padding: 0px 0px;
  margin-right: 0px;
  margin-left: -10px;
}
.projectviewDivhd {
  margin-top: 15px;
  margin-bottom: 35px;
}
.projectviewDivhd1 {
  font-size: 18px;
  margin-left: 10px;
  color: #06851d;
  border-bottom: 2px solid #06851d;
  width: 20%;
}
.projectViewCardDiv .tab-content .tab-pane {
  padding: 0px;
  padding-left: 10px !important;
  padding-top: 0px;
}
.projectViewCardDiv .tabforms {
  border: 1px solid #8080804a;
  /* padding: 0px 10px 0px 5px; */
}
.projectbasicdetailscontname {
  font-weight: 500;
  font-size: 16px;
  color: #516171;
  margin: 0;
}

.projectbasicdetailscontper {
  font-size: 14px;
  /* height: 60px; */
  line-height: 20px;
  text-transform: capitalize;
  margin: 0;
  margin-top: 5px;
  text-align: justify;
}
/* heading */
.menuheading {
  margin-left: 5.8%;
  font-size: 21px;
  margin-top: 11px;
  color: #06851d;

  display: block;

  font-weight: 600;
  text-transform: capitalize;
}
.cntrybtmmrgn {
  margin-bottom: 26px;
}
.global-class-name-user {
  margin-top: 7px;
  color: #bfb3b3;
  font-size: 1.33333333rem;
  line-height: 0.75em;
  vertical-align: middle;

  position: absolute;
  right: 14px;
}
.global-class-name-user:hover {
  color: red;
}
.proje-status {
  margin-top: -16px !important;
}
.projectCalculationViewTab .nav-tabs {
  background-color: white !important;
}
.projectCalculationViewTab .nav-tabs .nav-link {
  border: 1px solid transparent;
  width: 185px;
  text-align: center;
  color: #092032;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 0px !important;
  padding-bottom: 20px;
  color: #092032 !important;
  background: white !important;
  border-color: white !important;
  /* border-bottom: 3px solid #06851d !important; */
  padding-bottom: 20px;
}
.projectCalculationViewTab .nav-tabs .nav-link.active {
  border: 1px solid transparent;
  width: 185px;
  text-align: center;
  color: #092032;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 0px !important;
  padding-bottom: 20px;
  color: #06851d !important;
  background: white !important;
  border-color: white !important;
  border-bottom: 3px solid #06851d !important;
  padding-bottom: 20px;
}
.projectCalculationViewTab .tabforms {
  border: 0px solid #8080804a;
}
.calculationTable .table {
  color: #23282c;
  width: 80%;
  margin: auto;
}
.calculationTable {
  margin-top: 30px;
}
.calculationTable .table thead th {
  text-align: left;
  vertical-align: bottom;
  background-color: #757575;
  color: white;
}
.calculationTableSimplified {
  color: #23282c;
  margin-top: 30px;
  width: 80%;
}
.calculationTableSimplified .table thead th {
  text-align: left;
  vertical-align: bottom;
  background-color: #757575;
  color: white;
}

/* User modal box -changestatusmodal */
.mastr-mdl-hd-user {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 12px;
  /* border-bottom: 1px solid #c8ced3; */
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  background-color: rgb(255 255 255 / 70%);
  color: #6b6b6b;
  border-top-left-radius: 0.8rem;
  border-top-right-radius: 0.8rem;
  border-bottom: 1px solid #3dcd58;
  /* border-bottom-width: 20px; */
}
.modl-titl-align-usr {
  font-size: 22px !important;
  color: #000000b0;
}

.detailModal {
  padding-top: 15.5rem;
  background-color: rgba(0, 0, 0, 0.2);
}

.detailModal .modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}
.extra {
}
.projectSectn {
  margin-left: 15px;
  font-size: 14px;
  font-weight: 600;
  /* border-bottom: 2px solid #06851d; */
}
.rbt-aux {
  align-items: center;
  display: flex;
  bottom: 0;
  justify-content: center;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 5px !important;
  width: 34px !important;
}
.myprofCard {
  width: 60%;
  margin: auto;
  margin-top: 100px;
}
.MyProfileScn .card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0px solid #c8ced3 !important;
  border-radius: 1.25rem !important;
  box-shadow: 0px 2px 14px 0px rgb(134 133 133 / 33%);
}
.usrprof-side-img {
  width: 100%;
  margin: auto;
  margin-top: 60px;
  margin-bottom: 60px;
}
.userscndsectn {
  margin-top: 50px;
}
.profiledetailsfrstcontname2 {
  font-size: 14px;
  color: #516171;
  margin: 0px;
  margin-top: 0px;
  text-transform: capitalize !important;
  line-height: 2rem;
  font-weight: 900;
}
.profiledetailsbasicdetailsuser {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding-top: 5px;
  /* padding-left: 5px; */
  font-size: 14px;
  font-weight: 400;
  color: #5c6873;
  /* background-color: hwb(168deg 89% 8% / 91%); */
  background-clip: padding-box;
  border-radius: 0.25rem;
  overflow: auto;
  margin-bottom: 20px;
}
.profiledetailsbasicdetailsuser:not(.email) {
  text-transform: capitalize;
}
.profiledetailsfrstcontemail {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding-top: 5px;
  /* padding-left: 5px; */
  font-size: 14px;
  font-weight: 400;
  color: #5c6873;
  /* background-color: hwb(168deg 89% 8% / 91%); */
  background-clip: padding-box;
  border-radius: 0.25rem;
  overflow: auto;
  margin-bottom: 20px;
  text-transform: lowercase !important;
}

@media (max-width: 992px) {
  .serchNFilterDiv {
    padding-top: 20px;
  }
}
@media (max-width: 575px) {
  .usrAlgn .csrdSerchInpt1 input {
    width: 100%;
  }
}
.plusiconproject {
  margin-top: -31px;
  border: 2px solid #06851d !important;
  padding-left: 7px;
  padding-right: 7px;
  padding-bottom: 5px;
  padding-top: 5px;
  float: right;
  border-radius: 5px;
  margin-right: -40px;
  cursor: pointer;
}
.calculationhd1 {
  font-size: 14px;
  font-weight: 600;
  /* margin-left: 15px; */
  margin-bottom: 25px;
  margin-top: 20px;
}
.graphhdtotal {
  font-size: 16px;
  font-weight: 900;
  margin-bottom: 20px;
  text-align: center;
  margin-top: 10px;
}
.graphDivSection .row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.xslbtn {
  color: #242121;
  background-color: white;
  border-color: #06851d;

  padding-bottom: 6px;
  padding-top: 6px;
  box-shadow: 0 0 0 0 rgb(65 181 222 / 50%);
  border-radius: 30px;
  width: 145px;

  border: 1px solid green;
  float: right;
  margin-right: 0%;
  display: inline;
}
@media screen and (min-width: 989px) and (max-width: 1019px) {
  .xslbtn {
    position: absolute;
    left: -7px;
  }
}

@media screen and (min-width: 1019px) and (max-width: 1107px) {
  .xslbtn {
    position: absolute;
    left: -17px;
  }
}
@media screen and (max-width: 988px) {
  .xslbtn {
    margin-top: 10px;
    color: #242121;
    background-color: white;
    border-color: #06851d;
    padding-bottom: 6px;
    padding-top: 6px;
    box-shadow: 0 0 0 0 rgb(65 181 222 / 50%);
    border-radius: 30px;
    width: 145px;
    border: 1px solid green;

    float: left;
    margin-right: 0%;
  }
  .btnsearchfiltercol {
    margin-top: 10px;
    float: left;
  }
  .cmnsearchfilter {
    margin-left: -31px;
  }
  .btnfiltercol {
    margin-left: -11px;
  }
  .cardbdyfilter {
    flex: 1 1 auto;
    padding: 1.25rem;
    width: 100%;
    right: 8px !important;
    position: relative;
    border: 1px solid rgba(83, 79, 79, 0.452);
    border-radius: 10px;
    bottom: -14px;
  }
}
@media screen and (max-width: 750px) {
  .searchinsm {
    display: block !important;
  }
  .filterinsm {
    margin-top: 10px;
    margin-left: 20px;
  }
  .searchinsmwidth {
    width: 60%;
    display: flex;
  }
  .cmnSearchIcon {
    color: #9b9da0;
    position: relative;
    z-index: 50;
    left: 26px;
    bottom: -12px;
  }
}
@media screen and (min-width: 989px) and (max-width: 991px) {
  .btnsearchfiltercol {
    margin-left: -30px;
    margin-top: 10px;
  }
  .btnrow {
    margin-bottom: 56px;
  }
  .xslbtn {
    margin-top: 10px;
    margin-left: 10px;
    float: left !important;
  }
}
@media screen and (min-width: 989px) and (max-width: 1200px) {
  .cardbdyfilter {
    width: 140%;
    right: 72px;
  }
}
@media screen and (max-width: 991px) {
  .searchtypeef {
    margin-top: 10px;
  }
}

.searchinsm {
  display: flex;
}
.xslheading {
  background-color: #06851d;
}
.graphfrstsection {
  border: 1px solid #80808057;
  margin-left: 0px;
  padding-bottom: 40px;
  margin-top: 25px;
}
.reactgooglegraph-1 {
  height: 300px;
  width: 97%;
  margin-left: 15px;
}
.xslbtn-no-style {
  border: none;
  background-color: transparent;
  font-weight: bold;
}
.xslbtn-no-style:focus {
  outline: none !important;
  border: none;
}
.xslbtn-no-style:focus,
.focus-visible:focus:not(:focus-visible) {
  outline: 0;
  box-shadow: 0 0 0 0.2rem #fff, 0 0 0 0.35rem #069;
}
.xslbtn-no-style:focus:not(:focus-visible) {
  outline: 0;
  box-shadow: none;
}
.xslicon {
  font-size: 19px;
  padding-bottom: 3px;
  margin-left: 8px;
  color: green;
}
.carbongraphsection {
  /* margin-bottom: 10px; */
  margin-top: 65px;
}
@media print {
  .pdfButton {
    display: none;
  }
}
.pdfButton {
  color: #fff;
  background-color: #06851d;
  border-color: #06851d;
  border-radius: 30px;
  box-shadow: 0 0 0 0rem #06851d !important;
  font-weight: 600;
}
.pdfButton:hover {
  color: #fff;
  background-color: #06851d;
  border-color: #06851d;
  border-radius: 30px;
  box-shadow: 0 0 0 0rem #06851d !important;
  font-weight: 600;
}
.pdfSectionButton {
  margin-left: 80%;
  margin-top: 20px;
}
.tabforms {
  margin-top: 25px;
}
.pswd-display {
  color: #615e5e;
  font-size: 20px !important;
  display: flex;
  position: absolute;
}
.pswdvisibility {
  margin-top: 47px;
  margin-left: -45px;
  z-index: 40;
  font-size: 16px;
  position: absolute;
}

#password-field {
  width: 93%;
}
/* .password {
  width: 95%;
} */
.profileIcon {
  color: black;
  font-size: 20px !important;
  line-height: 0.75em;
  position: absolute;
  left: 60px;
  margin-top: -30px;
}
.profileIcon:hover {
  font-size: 20px;
  line-height: 0.75em;
  position: absolute;
  color: #f82500d2;
  cursor: pointer;
}
.pswd-lgn {
  /* margin-left: -35px; */
  margin-top: 35px;
  z-index: 1;
  cursor: pointer;
}
.pswd-lgn-icon {
  position: relative;
  left: -118px;
}

@media only screen and (max-width: 768px) {
  .pswd-lgn-icon {
    left: -95px;
  }
}

@media only screen and (max-width: 480px) {
  .pswd-lgn-icon {
    left: -65px;
  }
}

.pswd-lgn-icon-reset {
  position: relative;
  width: 0px;
  right: 130px;
  bottom: 3px;
}
@media only screen and (max-width: 1103px) {
  .pswd-lgn-icon-reset {
    left: -103px;
  }
}

.graphsubhd {
  text-align: center;
  margin-top: 15px;
  font-weight: 900;
}
.statuschangeIcon {
  background-color: brown;
  padding-bottom: 5px;
  padding-top: 5px;
  padding-left: 6px;
  padding-right: 6px;
  border-radius: 7px;
  margin-right: 7px;
  color: #fff;
}
.statuschangeIcon1 {
  background-color: brown;
  padding-bottom: 5px;
  padding-top: 5px;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 7px;
  margin-right: 7px;
  color: #fff;
}

.calculationTable .table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #c8ced3;
  /* text-transform: capitalize !important; */
}

.publishDelCnclBtn {
  /* width: 100px; */
  /* float: right; */
  background-color: rgba(73, 71, 71, 0.76) !important;
  border-color: #ccc !important;
  border-radius: 50px !important;
  color: #fff;
  border: 1px solid rgba(73, 71, 71, 0.76);
}

.publishDelCnclBtn:hover {
  background-color: rgb(143, 140, 140) !important;
  border-color: #ccc !important;
  border-radius: 50px !important;
  color: #fff;
}

.publishDelCnclBtn:active {
  background-color: #ccc !important;
  border-color: #ccc !important;
  border-radius: 50px !important;
  color: #fff;
}

.publishDelCnclBtn:focus {
  background-color: #ccc !important;
  border-color: #fff;
  border-radius: 50px !important;
  outline: 0;
  box-shadow: 0 0 0 0rem #ccc !important;
}
.btn-publish {
  margin-left: 90px;
}
.btnmrgn {
  margin-top: -131px;
}

/* .operation-submit-btn {
  background-color: #06851d !important;
  border-color: #06851d !important;

  padding-bottom: 5px;
  padding-top: 5px;
  border-radius: 63px !important;
  color: white !important;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  z-index: 5;
  transition: all 0.4s ease-in-out;
  border: 2px solid transparent;
  overflow: hidden;
  float: right;
  margin-right: 80px;
  font-weight: 600;
  box-shadow: 0 0 0 0rem rgba(65, 181, 222, 0) !important;
} */
/* .operation-submit-btn:hover {
  color: #fff;
  background-color: #06851d !important;
  border-color: #06851d !important;
  box-shadow: 0 0 0 0rem rgba(65, 181, 222, 0) !important;
}
.operation-submit-btn:active {
  color: #fff;
  background-color: #06851d !important;
  border-color: #06851d !important;
  box-shadow: 0 0 0 0rem rgba(65, 181, 222, 0) !important;
} */
.chartjs-render-monitor {
  margin-top: 60px;
}
.calculationTablebody {
  text-transform: capitalize;
}
.xslbtn-dwnld {
  border-radius: 30px;
  height: 34px;
  width: 99px;
}
.xslbtn-no-style:focus,
.focus-visible:focus:not(:focus-visible) {
  outline: 0;
  box-shadow: none !important;
}

#number-input::-webkit-outer-spin-button,
#number-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* project landing page share icon*/
.shareicon {
  padding: 5px;

  background-color: #0077b6;
  color: white;

  border-radius: 8px;
}

.shareModalTable {
  margin-top: 15px;
}
.custom-toast {
  background-color: gray;
  box-shadow: 2px 9px 47px -14px rgb(6 6 6 / 96%);
  border-radius: 10px;
}
.custom-toast-container {
  height: 100px; /* Adjust the height as desired */
  margin-left: 100px;
}
.custom-toast-container .Toastify__progress-bar {
  background-color: green;
}
button:focus {
  outline: 0px dotted transparent !important;
  outline: 0px auto transparent !important;
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #4dbd74;
  border-color: #4dbd74;
}

.revision th {
  padding-top: 14px;
  padding-bottom: 14px;
  text-align: left;
  background-color: #80808033;
  color: grey;
}

.revision td {
  color: grey;
  font-size: 16px;
  font-weight: bold;
}

.revisonbtn,
.revisonbtn:hover,
.revisonbtn:active,
.revisonbtn:focus {
  background-color: #06851d;
  border-radius: 4px !important;
  border-color: #06851d;
  outline: none;
  height: 32px;
}
.card {
  border-radius: 8px !important;
}

.delete-btn-rw {
  display: flex;
  justify-content: center;
}

.revisonbtn-modal,
.revisonbtn-modal:hover,
.revisonbtn-modal:active,
.revisonbtn-modal:focus {
  background-color: #06851d !important;
  border-color: #06851d !important;
  width: 100px;
  padding-bottom: 5px;
  padding-top: 5px;
  border-radius: 4px !important;
  color: white !important;
  text-transform: capitalize;

  font-size: 14px;
  cursor: pointer;
  z-index: 5;
  transition: all 0.4s ease-in-out;
  border: 2px solid transparent;
  overflow: hidden;
  box-shadow: 0 0 0 0.2rem #fff !important;
  font-weight: 600;
  margin: 0 5px; /* Add this line to provide 5px gap on both sides of the button */
}

.revision-cancel,
.revision-cancel:hover,
.revision-cancel:active,
.revision-cancel:focus {
  font-weight: 600;
  font-size: 14px;
  width: 100px;
  float: right;
  background-color: #fff;
  border-color: #06851d !important;
  border-radius: 4px !important;
  color: #06851d;
  border: 1px solid rgba(73, 71, 71, 0.76);
}
/* Add this to your CSS file */
.custom-toast {
  background: #fff !important;
  color: #0009 !important;
  margin-top: 99px;
  border: 1px solid #3acc61;
  font-weight: bold;
  font-size: 16px;
  border-radius: 4px;
}
.rev-icon {
  color: #06851d;
  font-size: 20px;
}
