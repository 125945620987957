* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.notifydatas,
.notifiheader {
  margin-top: 10px;
  padding: 10px 0px;
}
.notifydatas:hover {
  background-color: rgb(101 110 101 / 20%);
}
.notifyreficon {
  color: #7b7e7b;
  font-size: 30px;
}
.notifydatas:hover .notifyreficon {
  color: rgba(0, 0, 0, 0.781);
}
